<template>
  <b-container fluid="xxl">
    <b-card bg-variant="light" class="mb-3">
      <b-card-title class="text-center mb-5" :title="$t('insect.tableTitle')" />
      <GoldflamTable
        :new-item="newInsect"
        :all-items="filteredInsects"
        :edit-function="editInsectFunction"
        :delete-function="deleteInsectFunction"
        :table-fields="tableFields"
        sort-by="name"
      >
        <template v-slot:filters>
          <b-col class="col col-sm-4 col-md-3">
            <b-form-select v-model="filterOptions.showTaxonomy" :options="taxonomyOptions" class="taxonomy-select">
              <!-- This slot appears above the options from 'options' prop -->
              <template #first>
                <b-form-select-option :value="''" disabled>-- Taxonomy --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </template>
        <template v-slot:cell(actions)="row">
          <b-button
            :href="createGbifDotOrgSpeciesUrl(row.item.gbif_id)"
            target="_blank"
            size="xs"
            variant="outline-secondary"
            v-b-tooltip.bottom="$t('insect.gbif-details-link')"
          >
            <b-icon icon="bug-fill" scale="1.2" style="cursor: pointer" aria-hidden="true" />
          </b-button>
        </template>
        <template v-slot:extra-action-button>
          <b-button
            @click="copyGbifIds"
            variant="outline-primary"
            class="mr-3"
            size="sm"
            v-b-hover="handleCopyHover"
            v-b-tooltip.bottom.ds650="$t('insect.copy-gbif-ids.label')"
            ><b-icon :icon="copyButton.icon" class="mr-1" /> {{ $t(copyButton.text) }}</b-button
          >
        </template>
      </GoldflamTable>

      <b-modal
        id="addModal"
        v-model="modalControl.add.show"
        size="lg"
        centered
        :title="$t('insect.add-insect')"
        :ok-title="$t('general.save')"
        :cancel-title="$t('general.cancel')"
        cancel-variant="outline-secondary"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        :body-bg-variant="bodyBgVariant"
        :body-text-variant="bodyTextVariant"
        :footer-bg-variant="footerBgVariant"
        :footer-text-variant="footerTextVariant"
        @ok="saveInsect(editInsect)"
        :ok-disabled="!loadedInsect"
        @hidden="resetAddModal"
      >
        <b-form-group
          class="mt-4 mb-2"
          label-for="gbifIdInput"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('insect.gbif-id-search')"
        >
          <b-input-group>
            <b-form-input id="gbifIdInput" type="number" v-model="gbifIdField" autofocus />
            <b-input-group-append>
              <b-button
                @click="loadInsect()"
                variant="info"
                id="loadInsectButton"
                :disabled="isInputEmpty || busy"
                class="align-self-center"
                >{{ $t('insect.load-insect-preview') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <div v-if="loadedInsect && !busy">
          <b-form-group
            class="mt-4 mb-2"
            label-for="insectGbifIdField"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('insect.gbif-id-resolved')"
          >
            <b-row>
              <b-col cols="3">
                <b-form-input id="insectGbifIdField" type="text" v-model="editInsect.gbif_id" disabled />
              </b-col>
              <b-col cols="9">
                <b-form-text class="text-black-50" v-if="gbifIdsNotEqual"
                  >{{ $t('insect.backbone-version') }}<br />
                  <a :href="createGbifDotOrgSpeciesUrl(gbifIdField)" target="_blank">ID {{ gbifIdField }}</a> →
                  <a :href="createGbifDotOrgSpeciesUrl(editInsect.gbif_id)" target="_blank"
                    >ID {{ editInsect.gbif_id }}</a
                  ></b-form-text
                >
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="insectNameField"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('insect.name')"
          >
            <b-form-input id="insectNameField" type="text" v-model="editInsect.name" disabled />
          </b-form-group>
          <b-form-group
            class="mt-4 mb-2"
            label-for="insectTaxonomyLevelField"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('insect.taxonomy-level')"
          >
            <b-form-input id="insectTaxonomyLevelField" type="text" v-model="editInsect.taxonomy_level" disabled />
          </b-form-group>
        </div>
        <div v-else-if="alertMessage !== null">
          <b-alert show variant="warning">
            {{ alertMessage }}
          </b-alert>
        </div>
        <div v-else-if="busy">
          <LoadingSpinner />
        </div>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import GoldflamTable from '@/components/GoldflamTable.vue'
import { i18n } from '@/plugins/i18n'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
  name: 'ManageInsects',
  mixins: [ApiMixin, NotificationMixin],
  components: { GoldflamTable, LoadingSpinner },

  data() {
    return {
      gbifIdField: '',
      editInsect: {
        gbif_id: null,
        name: null,
        taxonomy_level: null
      },
      newInsect: {
        gbif_id: null,
        name: null,
        taxonomy_level: null
      },
      busy: false,
      loadedInsect: false,
      alertMessage: null,
      tableFields: [
        { key: 'gbif_id', label: this.$t('insect.gbif-id'), sortable: true, width: '120px' },
        { key: 'name', label: this.$t('insect.name'), sortable: true },
        { key: 'actions', label: this.$t('general.table.actions'), sortable: false, width: '60px' }
      ],
      filterOptions: {
        showTaxonomy: 'ORDER'
      },
      taxonomyOptions: [
        { value: 'ORDER', text: i18n.t('dataset.insect-order') },
        { value: 'FAMILY', text: i18n.t('dataset.insect-family') },
        { value: 'GENUS', text: i18n.t('dataset.insect-genus') },
        { value: 'SPECIES', text: i18n.t('dataset.insect-species') }
      ],
      allInsectOrders: [],
      allInsectFamilies: [],
      allInsectGenuses: [],
      allInsectSpecies: [],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      modalControl: {
        add: {
          show: false
        }
      },
      copyButton: {
        icon: 'clipboard',
        text: 'insect.copy-gbif-ids.copy'
      }
    }
  },
  mounted() {
    this.getAllInsects()
  },
  computed: {
    filteredInsects() {
      let list
      if (this.filterOptions.showTaxonomy === 'ORDER') {
        list = this.allInsectOrders
      } else if (this.filterOptions.showTaxonomy === 'FAMILY') {
        list = this.allInsectFamilies
      } else if (this.filterOptions.showTaxonomy === 'GENUS') {
        list = this.allInsectGenuses
      } else if (this.filterOptions.showTaxonomy === 'SPECIES') {
        list = this.allInsectSpecies
      }
      return list
    },
    isInputEmpty() {
      return this.gbifIdField === null || this.gbifIdField === ''
    },
    gbifIdsNotEqual() {
      return this.editInsect.gbif_id !== null && this.editInsect.gbif_id.toString() !== this.gbifIdField
    }
  },
  methods: {
    editInsectFunction() {
      this.modalControl.add.show = true
    },
    deleteInsectFunction() {},
    createGbifDotOrgSpeciesUrl(gbifId) {
      return `https://www.gbif.org/species/${gbifId}`
    },
    getAllInsects() {
      this.getAllInsectOrders()
      this.getAllInsectFamilies()
      this.getAllInsectGenuses()
      this.getAllInsectSpecies()
    },
    getAllInsectOrders() {
      let self = this
      self.getRequest(
        `/insect_orders`,
        new RequestConfig().onSuccess(res => {
          self.allInsectOrders = res.data
        })
      )
    },
    getAllInsectFamilies() {
      let self = this
      self.getRequest(
        `/insect_families`,
        new RequestConfig().onSuccess(res => {
          self.allInsectFamilies = res.data
        })
      )
    },
    getAllInsectGenuses() {
      let self = this
      self.getRequest(
        `/insect_genuses`,
        new RequestConfig().onSuccess(res => {
          self.allInsectGenuses = res.data
        })
      )
    },
    getAllInsectSpecies() {
      let self = this
      self.getRequest(
        `/insect_species`,
        new RequestConfig().onSuccess(res => {
          self.allInsectSpecies = res.data
        })
      )
    },
    saveInsect() {
      let self = this
      self.postRequest(
        `/gbif_insects/` + self.editInsect.gbif_id,
        {},
        new RequestConfig().onSuccess(() => {
          self.getAllInsects()
        })
      )
    },
    resetAddModal() {
      this.editInsect = this.newInsect
      this.gbifIdField = ''
    },
    loadInsect() {
      let self = this
      self.busy = true
      this.getRequest(
        `/gbif_insects/` + self.gbifIdField,
        new RequestConfig()
          .onSuccess(res => {
            self.editInsect = res.data
            self.loadedInsect = true
            self.busy = false
          })
          .onError(err => {
            self.alertMessage = err.response.data.message
            self.busy = false
          })
      )
    },
    resetLoadedInsect() {
      this.alertMessage = null
      this.loadedInsect = false
      self.busy = false
    },
    handleCopyHover(hovered) {
      if (hovered) {
        this.copyButton.icon = 'clipboard-plus'
      } else if (this.copyButton.icon === 'clipboard-check') {
        // do nothing, gets resetted by the setTimeout below
      } else {
        this.copyButton.icon = 'clipboard'
      }
    },
    copyGbifIds() {
      let allInsects = this.allInsectOrders
        .sort((a, b) => a.gbif_id - b.gbif_id)
        .concat(this.allInsectFamilies.sort((a, b) => a.gbif_id - b.gbif_id))
        .concat(this.allInsectGenuses.sort((a, b) => a.gbif_id - b.gbif_id))
        .concat(this.allInsectSpecies.sort((a, b) => a.gbif_id - b.gbif_id))
      let allInsectGbifIds = allInsects.map(i => i.gbif_id).join(',')
      navigator.clipboard.writeText(allInsectGbifIds)
      this.copyButton.icon = 'clipboard-check'
      this.copyButton.text = 'insect.copy-gbif-ids.copied'
      setTimeout(() => {
        this.copyButton.icon = 'clipboard'
        this.copyButton.text = 'insect.copy-gbif-ids.copy'
      }, 1500)
    }
  },
  watch: {
    gbifIdField: function () {
      this.resetLoadedInsect()
    }
  }
}
</script>
